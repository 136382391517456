import React from 'react';
import { PrismicRichText } from '@prismicio/react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { linkResolver } from '../utils/link_resolver'
import { StaticQuery, graphql } from 'gatsby'

function AverageRating(data) {
  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (data.rating > i) {
      ratings.push(<span className="text-orange" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-diamond-30" key={i}>&#9733;</span>)
    }
  }
  return (
    <div className="flex flex-row gap-1">
      {ratings}
    </div>
  )
}

function CreateRatings(p) {
  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (p.rating > i) {
      ratings.push(<span className="text-lg text-diamond" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-lg text-diamond-30" key={i}>&#9733;</span>)
    }
  }
  return (
    <div>
      {ratings}
    </div>
  )
}

function GoogleReviews(p) {
  //console.log('p: ', p)
  return (
    <>
      {p.reviewItems.map((entry) => {
        let excerpt = entry.text.length > 150 ? entry.text.substring(0, 150) + "..." : entry.text;
        return (
          <div key={entry.author} className="mb-6 px-6 font-poppins">
            <div className="prose prose-p:m-0 prose-p:p-0 text-diamond leading-tight">
              <div className="flex flex-row gap-1">
                <CreateRatings rating={entry.rating} />
              </div>
              <div className="mt-1 mb-3">
                <p><strong className="capitalize">{entry.author}</strong> ({entry.time})</p>
              </div>
              <div>
                <p>&ldquo;{excerpt}&rdquo;</p>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}


const ProductReviews = ({isReviewsOpen,setReviewsOpen}) => (
  <StaticQuery
    query={graphql`
      {
        prismicGoogleReviews {
          data {
            heading
            text {
              richText
            }
            google_reviews {
              time
              text
              rating
              image {
                gatsbyImageData
                alt
              }
              author
            }
            total_reviews
            average_rating
          }
        }
      }
    `} 
    render = { data => (
    <div>
      <Transition.Root show={isReviewsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setReviewsOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment} 
                  enter="transform transition ease-in-out duration-500" 
                  enterFrom="-translate-x-full" 
                  enterTo="translate-x-0" 
                  leave="transform transition ease-in-out duration-500" 
                  leaveFrom="translate-x-0" 
                  leaveTo="-translate-x-full" 
                  afterLeave={() => setReviewsOpen(false)}
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="font-poppins text-xl sm:text-2xl font-semibold text-orange">
                            {data.prismicGoogleReviews.data.heading}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button type="button" className="rounded-md bg-white text-grey-6 hover:text-black focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2" onClick={() => setReviewsOpen(false)}>
                              <span className="sr-only">Close panel</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mb-6 px-6 flex flex-row items-center gap-6 font-poppins font-semibold text-orange">
                        <div className="text-xl sm:text-2xl">
                          <AverageRating rating={data.prismicGoogleReviews.data.average_rating} />
                        </div>
                        <div className="text-lg">
                          {data.prismicGoogleReviews.data.total_reviews} Reviews
                        </div>
                      </div>
                      <div className="mb-6 px-6 font-poppins">
                        <div className="prose prose-p:font-semibold text-black leading-tight">
                          <PrismicRichText linkResolver={linkResolver} field={data.prismicGoogleReviews.data.text.richText} />
                        </div>
                      </div>
                      <GoogleReviews reviewItems={data.prismicGoogleReviews.data.google_reviews} />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
    )}
  />
)

export default ProductReviews
